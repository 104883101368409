<script lang="ts">
export default {
    computed: {
        crumbs() {
            const path = this.filterPath

            const params = path.startsWith('/')
                ? path.substring(1).split('/')
                : path.split('/')

            let crumbs = []
            let breadcrumbPath = ''

            params.forEach((param) => {
                param = param.replace(/-/g, '') // REMOVE SPECIAL LETTES
                breadcrumbPath += `/${param}`
                crumbs.push({title: param, path: breadcrumbPath})
            })

            crumbs.unshift({title: 'homepage', path: 'homepage'})
            crumbs = crumbs.filter(path => path)
            if (this.$route.params.k) {
                const indexParam = crumbs.findIndex(crumb => crumb.title === String(this.$route.params.k))
                crumbs.splice(indexParam, 1)
            }
            return crumbs
        },
        filterPath() {
            const path = this.$route.path

            let fullPath = this.$route.fullPath
            if (path.endsWith('/')) {
                if (this.$route.hash)
                    fullPath = fullPath.split('#')[0].slice(0, -1)
                else
                    fullPath = fullPath.slice(0, -1)
            }
            else if (this.$route.hash) {
                fullPath = fullPath.split('#')[0]
            }
            if (Object.keys(this.$route.query).length)
                return fullPath.split('?')[0]

            return fullPath
        },
    },
}
</script>

<template>
    <v-breadcrumbs :items="crumbs" class="pl-1 d-block" tag="ol">
        <template #prepend>
            <v-icon icon="mdi-home" size="small" class="pr-3" />
        </template>
        <template #title="{ item }">
            <NuxtLink :to="item.path === 'homepage' ? '/' : `${item.path}`">
                {{ $translate(item.title) }}
            </NuxtLink>
            <!-- href: 'breadcrumbs_dashboard',to redirect -->
        </template>
        <template #divider>
            <v-icon icon="mdi-chevron-right" />
        </template>
    </v-breadcrumbs>
</template>
