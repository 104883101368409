<script lang="ts">
import formFieldsInit from '../../utils/formFieldsInit.vue'
import FormLabel from './label.vue'

export default {
    name: 'YesnoField',
    components: { FormLabel },
    extends: formFieldsInit,
    props: {
        labelSidePosition: { type: Boolean, required: false },
        customLabel: { type: String, required: false, default: '' },
        customTrueText: { type: String, required: false, default: '' },
        customFalseText: { type: String, required: false, default: '' },
        checkbox: { type: Boolean, required: false },
    },
    emits: ['updateData'],
}
</script>

<template>
    <FormLabel v-if="(data || injectedData || formService?.data) && (value !== undefined || rule)">
        <input
            v-if="!value"
            type="hidden"
            :name="customId ?? dataKey"
            value="false"
        >
        <v-checkbox
            v-if="rule && checkbox"
            :id="customId ?? dataKey"
            v-model="value"
            :name="customId ?? dataKey"
            :disabled="disabled"
            :error-messages="getErrors()"
            persistent-hint
            :hint="getInfo()"
            @update:model-value="$emit('updateData', $event); updateData($event); injectedValidation?.$touch()"
        >
            <template #label>
                <legend> {{ labelSidePosition ? $translate(`${translationName}.${dataKey}`) : customLabel }} </legend>
            </template>
        </v-checkbox>
        <v-switch
            v-else-if="rule && !checkbox"
            :id="customId ?? dataKey"
            v-model="value"
            :name="customId ?? dataKey"
            :label="labelSidePosition ? $translate(`${translationName}.${dataKey}`) : customLabel"
            color="success"
            :disabled="disabled"
            persistent-hint
            :hint="getInfo()"
            :error-messages="getErrors()"
            @update:model-value="$emit('updateData', $event); updateData($event); injectedValidation?.$touch()"
        />
        <div v-else>
            <v-chip
                v-if="value"
                :variant="$config.public.chips_variant || 'tonal'"
                color="success"
            >
                {{ customTrueText ? customTrueText : 'Oui' }}
            </v-chip>
            <v-chip
                v-else
                :variant="$config.public.chips_variant || 'tonal'"
                color="error"
            >
                {{ customFalseText ? customFalseText : 'Non' }}
            </v-chip>
        </div>
    </FormLabel>
</template>
